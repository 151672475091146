import React from "react";

// Layout
import Seo from "../components/layout/Seo/Seo";

// Generic
import BasicPage from "../components/shared/BasicPage/BasicPage";

const StudentsTermAndConditionsPage = (props) => {
  return (
    <>
      <Seo
        title="Students Terms and Conditions"
        slug={props.location.pathname}
      />
      <BasicPage>
        <h1 className="h2 text-center mb-12">Bootcamp Terms and Conditions</h1>
        <div className="c-prose c-prose--ol-secondary-alpha-lower">
          <h2 className="h5" data-number-before="1">
            Information about us and these terms
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              1.1
            </span>
            These are the terms and conditions for Northcoders Limited. They set
            out the basis on which we make available our technology bootcamps
            for our prospective students.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              1.2
            </span>
            We are a private limited company incorporated in England and Wales
            with company number 09912193. Our registered office address is at
            Manchester Technology Centre, Oxford Road, Manchester, England, M1
            7ED.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              1.3
            </span>
            Please read these terms and conditions carefully before you apply
            for one of our courses. We may amend these terms (and any of our
            policies referred to in them) from time to time to reflect updates
            to our courses.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              1.4
            </span>
            These Terms and the documents and policies referred to in them
            establish a legally binding agreement between you and us in
            connection with your proposed or actual enrolment on one of our
            Courses.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              1.5
            </span>
            We can be contacted in connection with these Terms at our registered
            office address or otherwise by email at hello@northcoders.com.
            Routine contact in connection with your Course will take place with
            your tutor, whose contact details shall be provided to you via the
            Slack System once you have enrolled on the Course.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              1.6
            </span>
            <strong>
              Your special attention is drawn to clauses 6, 7, 8, 14, 16 and 19
              of these Terms.
            </strong>
          </p>

          <h2 className="h5" data-number-before="2">
            Use of defined terms
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              2.1
            </span>
            From this point onwards, these terms and conditions make use of the
            following defined terms:
          </p>
          <div className="overflow-x-scroll w-full">
              <table
                style={{
                    borderCollapse: 'separate',
                    borderSpacing: '0 1em',
                    tableLayout: 'fixed'
                }}
                className="text-center w-full"
                      >
                <thead>
                  <tr>
                    <th>Term</th>
                    <th>Definition</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="font-bold">Application</td>
                    <td>your application to study on one of our Courses;</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Code of Ethics</td>
                    <td>
                      means our code of ethics policy, which can be found at{" "}
                      <a
                        href="https://northcoders.com/code-of-ethics"
                        target="_blank"
                      >
                        https://northcoders.com/code-of-ethics
                      </a>
                      . This may be updated from time to time by us;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Contract</td>
                    <td>
                      the legally binding contract between you and us relating to
                      your enrolment on the Course;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Course / Courses</td>
                    <td>
                      the technology bootcamp courses that we make available for
                      prospective students from time-to-time, which shall be as
                      initially referred to on our Website, one of which may be
                      subject to your Application;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Course Fees</td>
                    <td>
                      the course fees that you are required to pay in order to enrol
                      on one of our Courses;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Course Materials</td>
                    <td>
                      any documentation, literature, materials, technology
                      (including, without limitation, software and associated
                      products) we make available to you throughout the Course;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Course Tiers</td>
                    <td>
                      the “Northcoders Core” and “Northcoders Career Plus” tier
                      options for the delivery of our Courses, as described on our
                      Website;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Curriculum</td>
                    <td>
                      the curriculum for our Courses, which is available via the
                      Website or otherwise on request;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">events outside of our control</td>
                    <td>has the meaning given to it in clause 15.1;</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Entry Challenge</td>
                    <td>
                      an entry challenge which shall comprise a technical exercise
                      followed by a discussion with our team, the purpose of which
                      is to determine your suitability for enrolment on the Course;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Initial Learning Activities</td>
                    <td>has the meaning given to it in clause 9.1</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Intellectual Property Rights</td>
                    <td>
                      patents, utility models, rights to inventions, copyright and
                      neighbouring and related rights, moral rights, trade marks and
                      service marks, business names and domain names, rights in get-
                      up and trade dress, goodwill and the right to sue for passing
                      off or unfair competition, rights in designs, rights in
                      computer software, database rights, rights to use, and protect
                      the confidentiality of, Confidential Information (including
                      know-how and trade secrets) and all other intellectual
                      property rights, in each case whether registered or
                      unregistered and including all applications and rights to
                      apply for and be granted, renewals or extensions of, and
                      rights to claim priority from, such rights and all similar or
                      equivalent rights or forms of protection which subsist or will
                      subsist now or in the future in any part of the world.
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Northcoders/we/us/our</td>
                    <td>Northcoders Limited;</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Offer Letter</td>
                    <td>
                      the letter from us to you which sets out our offer to enrol
                      you on one of our Courses in accordance with these Terms;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Slack System</td>
                    <td>
                      our internal communications system, via which we will
                      communicate with you in connection with your Course;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Start Date</td>
                    <td>
                      the date on which your Course will start, as set out in the
                      Offer Letter;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Statutory Cancellation Period</td>
                    <td>has the meaning given to it in clause 8.1;</td>
                  </tr>
                  <tr>
                    <td className="font-bold">Terms</td>
                    <td>
                      these terms and conditions, which may be amended from time-to-
                      time in accordance with clause 20.3;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">VAT</td>
                    <td>
                      value added tax or equivalent sales tax that is chargeable in
                      the UK;
                    </td>
                  </tr>
                  <tr>
                    <td className="font-bold">Website</td>
                    <td>
                      https://northcoders.com and such other domain(s) and/or
                      platform(s) that we may operate from time-to-time in
                      connection with our Courses.
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>

          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              2.2
            </span>
            These Terms incorporate the following rules of interpretation:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                2.2.1
              </span>
              The Schedules form part of these Terms and a reference to a
              Schedule is to a Schedule of these Terms.
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                2.2.2
              </span>
              Unless the context otherwise requires, words in the singular
              include the plural and in the plural include the singular.
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                2.2.3
              </span>
              A reference to legislation or a legislative provision is a
              reference to it as amended, extended or re-enacted from time to
              time and includes all subordinate legislation made from time to
              time under that legislation or legislative provision.
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                2.2.4
              </span>
              A reference to writing or written excludes fax but not email.
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                2.2.5
              </span>
              Where these Terms refer to the Contract that reference is to the
              Contract formed between you and us following your acceptance of an
              Offer Letter in accordance with clause 6.
            </p>
          </div>

          <h2 className="h5" data-number-before="3">
            Our Courses
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              3.1
            </span>
            Our Courses and Course Tiers are described on our Website.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              3.2
            </span>
            The Course Fees that you shall be required to pay depend on the
            Course that you enrol on and the Course Tier that you select.
          </p>

          <h2 className="h5" data-number-before="4">
            Eligibility to Study
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              4.1
            </span>
            You are responsible for ensuring that you have the right to study in
            the United Kingdom and, where required, that you have a valid visa
            in place. You can find out more information about visas at:{" "}
            <a href="https://www.gov.uk/student-visa" target="_blank">
              https://www.gov.uk/student-visa
            </a>
            .
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              4.2
            </span>
            We may require that you produce valid documentation that shows your
            address (such as, for example, a utility bill, passport or driving
            licence) and proof of immigration status when completing your
            Application. We will notify you where this is required.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              4.3
            </span>
            If we agree to enrol you on one of our Courses, you agree that you
            will be required to:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                4.3.1
              </span>
              obtain and use your own PPE (personal protective equipment);
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                4.3.2
              </span>
              ensure that you have headphones and personal computing equipment
              (laptop/desktop) which will permit you to stream online lectures;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                4.3.3
              </span>
              provide your own computer for the duration of the Course (which
              should have 8gb of RAM, an Intel Core i5 process or equivalent,
              with a 256 GB hard drive disk, and either an inbuilt or external
              webcam, or such other operating requirements as we may notify to
              you). If you are using MacOS (on an Apple computer), some software
              for our Courses is only compatible with recent versions, so you
              should ensure that your operating systems are updated before you
              enrol on the Course. You can read more about this here:{" "}
              <a
                href="https://support.apple.com/en-gb/guide/mac-help/mchlpx1065/mac"
                target="_blank"
              >
                https://support.apple.com/en-gb/guide/mac-help/mchlpx1065/mac
              </a>
              . If you are using a virtual machine for the course, you may have
              reduced resources; and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                4.3.4
              </span>
              ensure that you can work comfortably on your computer in
              accordance with applicable standards relating to posture and
              health.
            </p>
          </div>

          <h2 className="h5" data-number-before="5">
            Application Procedure
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              5.1
            </span>
            Where you wish to study on one of our Courses you must complete an
            Application, which is available via the Website.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              5.2
            </span>
            You must provide the following information in your Application:
          </p>

          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.1
              </span>
              full name;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.2
              </span>
              preferred pronouns;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.3
              </span>
              email address;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.4
              </span>
              residential address;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.5
              </span>
              mobile number;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.6
              </span>
              ethnic origin;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.7
              </span>
              any special educational needs and disabilities (SENDs); and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.2.8
              </span>
              your preferred Course and Course Tier; and
            </p>
            <p>
              together with such additional information that we may request from
              you in connection with your Application.
            </p>
          </div>

          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              5.3
            </span>
            Where, following our review of your Application, we consider that
            you may be suitable for enrolment on the Course, we may invite you
            to complete an Entry Challenge, the content and forum of which will
            be communicated to you in advance.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              5.4
            </span>
            We may refuse to enrol you on a Course where we consider that you
            are not suitable for it. By way of example only, the reasons why we
            may refuse enrolment on a Course include:
          </p>

          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.1
              </span>
              you do not display enough technical knowledge or abilities during
              the Entry Challenge;
            </p>

            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.2
              </span>
              you are unable to explain your answers during the Entry Challenge;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.3
              </span>
              in our reasonable opinion, you have cheated on the Entry
              Challenge;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.4
              </span>
              you fail to demonstrate an expected level of commitment to your
              Course;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.5
              </span>
              you fail to demonstrate an expected level of communication in
              order to complete your Course;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.6
              </span>
              you display a poor and/or negative attitude during the Entry
              Challenge;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.7
              </span>
              if you represent that you are a Department for Education funded
              student and the Department for Education fails to provide funding;
              and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.4.8
              </span>
              you act in breach of our Code of Ethics,
            </p>
            <p>
              however, we reserve the right to refuse enrolment on our Courses
              for any reason whatsoever at our absolute discretion.
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              5.5
            </span>
            In determining that you are not suitable for enrolment on a Course
            we may, at our discretion:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.5.1
              </span>
              recommend the completion of additional courses or learning
              (whether provided by us or by third parties) prior to enrolling on
              the Course;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.5.2
              </span>
              delay your enrolment on the Course until such time as we may
              determine, in our sole discretion, that you are suitable to begin
              the Course; or
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                5.5.3
              </span>
              reject your Application.
            </p>
          </div>

          <h2 className="h5" data-number-before="6">
            Offers and Enrollment -{" "}
            <strong>SPECIAL ATTENTION IS DRAWN TO THIS CLAUSE</strong>
          </h2>

          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              6.1
            </span>
            Where, following our review of your Application and your completion
            of the Entry Challenge, we consider that you are suitable for
            enrolment on your desired Course, we may offer to enrol you on the
            Course by sending you an Offer Letter.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              6.2
            </span>
            Your Offer Letter will contain the following information:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                6.2.1
              </span>
              the Course name and your Course Tier;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                6.2.2
              </span>
              the Start Date;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                6.2.3
              </span>
              instructions on how to secure your place on the Course;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                6.2.4
              </span>
              the deadline to secure your place on the Course; and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                6.2.5
              </span>
              Entry Challenge feedback,
            </p>
            <p>
              together with such additional information that we consider is
              relevant in connection with the Course.
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              6.3
            </span>
            The Offer Letter constitutes a contractual offer from us to you in
            connection with your enrolment on the Course in accordance with the
            terms of that Offer Letter and these Terms.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              6.4
            </span>
            You can accept the Offer Letter by signing and returning it to us
            (by email or post) or otherwise by communicating your acceptance of
            it (and these Terms) to us. Once an Offer Letter has been accepted
            by you, a legally binding Contract between you and us will be formed
            on the basis of these Terms and any additional information set out
            in our Offer Letter.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              6.5
            </span>
            Where there is any conflict or inconsistency between the provisions
            of the Offer Letter and these Terms, the provisions of the Offer
            Letter shall prevail to the extent of such conflict or
            inconsistency.
          </p>

          <h2 className="h5" data-number-before="7">
            Course Fees -{" "}
            <strong>SPECIAL ATTENTION IS DRAWN TO THIS CLAUSE</strong>
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.1
            </span>
            Our Course Fees are initially referred to on our Website and shall
            be confirmed in the Offer Letter.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.2
            </span>
            Your payment obligations towards us depend on whether you are:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                7.2.1
              </span>
              a private, fee-paying, student;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                7.2.2
              </span>
              being sponsored to complete the Course by a third-party; or
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                7.2.3
              </span>
              enrolling on the Course in connection with a scholarship that has
              been funded by the Department for Education.
            </p>
          </div>
          <p>
            <u>Private Students</u>
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.3
            </span>
            You will become responsible for paying the full Course Fees
            immediately on acceptance of the Offer Letter and must be paid in
            accordance with the payment terms set out in the Offer Letter.
          </p>
          <p>
            <u>Sponsored Students</u>
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.4
            </span>
            You are ultimately responsible for payment of the Course Fees. Where
            a third-party sponsor agrees to pay the Course Fees on your behalf
            and they fail to do so in accordance with the payment terms set out
            in the Offer Letter, you will be required to pay such Course Fees
            should you wish to remain on the Course.
          </p>
          <p>
            <u>Department for Education Students</u>
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.5
            </span>
            The Course Fees will be funded directly by the Department for
            Education.
          </p>
          <p>
            <u>General</u>
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.6
            </span>
            The due date for payment of the Course Fees shall be set out in the
            Offer Letter.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.7
            </span>
            The Course Fees are inclusive of any applicable VAT.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.8
            </span>
            Payment of the Course Fees and any Non-Refundable Deposit shall be
            made to the bank account we nominate in the Offer Letter.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              7.9
            </span>
            If you do not pay the Course Fees and any Non-Refundable Deposit in
            full or on the due date(s) for payment set out in the Offer Letter,
            we reserve the right to refuse your enrolment on the Course and
            terminate the Contract. We may also take legal action against you to
            cover any unpaid amount, together with interest at a rate of 2%
            above the Bank of England’s base rate from time-to-time. This
            interest accrues on a daily basis from the due date for payment
            until the date of actual payment of the overdue amount, whether
            before or after judgment. You will be required to pay us the
            interest together with any overdue amount.
          </p>

          <h2 className="h5" data-number-before="8">
            Cancellation Rights -{" "}
            <strong>SPECIAL ATTENTION IS DRAWN TO THIS CLAUSE</strong>
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              8.1
            </span>
            You have a 14-day statutory cancellation period starting on the day
            after the day the Contract between us is formed{" "}
            <strong>(Statutory Cancellation Period)</strong> during which you
            can change your mind and decide that you no longer wish to study on
            the Course.
          </p>

          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              8.2
            </span>
            If you decide to change your mind during the Statutory Cancellation
            Period you will, subject to clause 8.4, be entitled to receive a
            refund of any Course Fees that you have paid. Any refund will be
            made by us within 14 days of your cancellation to the bank account
            that you nominate in writing to us.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              8.3
            </span>
            To cancel the Contract, you can either complete the Cancellation
            Form contained in Schedule 1 to these Terms or otherwise notify us
            in writing in accordance with clause 1.5, above.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              8.4
            </span>
            In some circumstances, either the Start Date for your Course or the
            timetable during which you must complete Initial Learning Activities
            (see clause 9, below) may commence before the end of the Statutory
            Cancellation Period. Where this is the case, you expressly agree
            that we will start performing our obligations under the Contract
            during the Statutory Cancellation Period. If, once we have started
            the performance of our obligations but during the Statutory
            Cancellation Period, you decide to change your mind, you will be
            liable for paying a proportion of the Course Fees to cover the
            period during we performed our obligations.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              8.5
            </span>
            You may still change your mind outside of the Statutory Cancellation
            Period, but you will not be entitled to receive a refund of any
            Course Fees or Non-Refundable Deposit that you have made.
          </p>

          <h2 className="h5" data-number-before="9">
            Initial Learning Activities
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              9.1
            </span>
            You may be required to undertake and complete a pack of self-study
            materials that we set prior to the Start Date{" "}
            <strong>(Initial Learning Activities)</strong>, which are intended
            to prepare you for the Course and familiarise you with the tools you
            will be required to use.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              9.2
            </span>
            There will not be an opportunity to recomplete or otherwise
            substantively review the Initial Learning Activities again during
            the Course. It is our expectation that the Initial Learning
            Activities will be fully and accurately complete before the Start
            Date.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              9.3
            </span>
            Where you fail to successfully complete the Initial Learning
            Activities 7 days prior to the Start Date, we may at our discretion:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                9.3.1
              </span>
              require you to reattempt the Initial Learning Activities; or
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                9.3.2
              </span>
              cancel your place on the Course.
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              9.4
            </span>
            Where your place on the Course is cancelled in accordance with
            clause 9.3.2, we will refund a pro-rata amount of the Course Fees
            that have been paid by you. The total refund we make shall be at our
            discretion, but in making any refund we will take into account the
            obligations that we have already performed, as well as the
            engagement and effort you displayed when completing the Initial
            Learning Activities.
          </p>

          <h2 className="h5" data-number-before="10">
            Course Attendance and Delivery Methods
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.1
            </span>
            You acknowledge that our Courses are intensive and require
            attendance (either physically or digitally, dependent on the Course)
            and engagement by you in order to ensure their successful
            completion.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.2
            </span>
            Our Courses do not run on bank holidays, and we are closed
            throughout the period starting on Boxing Day and ending on New Years
            Day. Any Course that is in flight during this period will be
            extended by the equivalent missed days in the subsequent year. If
            your Course runs during the above period, we will make you aware of
            any closure dates.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.3
            </span>
            You acknowledge that we may from time to time and in our absolute
            but reasonable discretion, elect to either:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                10.3.1
              </span>
              change the location (within the relevant city or regional area) of
              the campus or premises from which the Course may be delivered;
              and/or
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                10.3.2
              </span>
              deliver the Course and associated teaching support remotely,
            </p>

            <p>
              including, without limitation, in circumstances where we consider
              it reasonable to do so in the best interests of the health,
              safety, physical and/or mental wellbeing of our students, faculty
              members and support staff or otherwise due to events outside of
              our control (as defined in clause 15.1).
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.4
            </span>
            We will use all reasonable endeavours to deliver our Courses in
            accordance with their Curriculum. However, we shall be entitled to
            make reasonable changes to the Curriculum (including, without
            limitation, the syllabus and structure of the Course) where we
            reasonably consider that the Course will be improved as a
            consequence. We will use our reasonable endeavours to Curriculum
            amendments to a minimum and notify you of those amendments before
            they come into effect. You have a right to object to any material
            amendments to the Curriculum.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.5
            </span>
            Notice of changes under clause 10.3 and clause 10.4 will be provided
            through the Slack system.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.6
            </span>
            You agree that it shall be your responsibility to check the Slack
            System before 9am on a daily basis to stay informed of any delivery
            method changes. We will use our reasonable endeavours to provide as
            much notice as practicable of any changes in delivery methods and
            minimise any disruption that any such changes may cause. Course
            content that is delivered remotely may be delivered as either live
            or pre-recorded broadcasts and will be made available online or by
            video-link.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              10.7
            </span>
            We take attendance on our Courses seriously and reserve the right to
            terminate your place on the Course should your attendance drop below
            our reasonable standards. You agree to keep any absence to an
            absolute minimum and, unless prevented by extreme circumstances,
            shall be required to obtain prior consent from your Tutor. We will
            have no automatic obligation to refund any Course Fees to you where
            we exercise our right under this clause. Any such refund shall be
            made at our absolute discretion.
          </p>
          <h2 className="h5" data-number-before="11">
            Your Conduct
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              11.1
            </span>
            In enrolling on one of our Courses, you confirm that your aim is to
            improve your material circumstances, whether that is through
            improving your role in an existing career or seeking a new career in
            a different industry.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              11.2
            </span>
            To achieve this, you confirm that you will engage with the Course,
            our careers advisors and mentors, and that attend interviews (or
            similar events) when they have been arranged for you (dependent on
            your Course Tier).
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              11.3
            </span>
            Throughout the Course, where required, you also confirm that you
            will pair program with other students on the Course and members of
            our teaching team regardless of age, disability, gender
            reassignment, marital or civil partner status, pregnancy or
            maternity, race, colour, nationality, ethnic or national origin,
            religion or belief, sex or sexual orientation.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              11.4
            </span>
            Without limiting your obligations under clause 11.3, you agree to
            comply at all times with our Code of Ethics.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              11.5
            </span>
            We have the right to either suspend and/or expel you from the Course
            in the event that you breach this clause 11 and/or the Code of
            Ethics either materially or repeatedly.
          </p>
          <h2 className="h5" data-number-before="12">
            Performance Standards
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              12.1
            </span>
            In rare circumstances, and despite all our best efforts, some
            students struggle to adjust to the pace and demands of our Courses,
            and we may decide at our absolute but reasonable discretion that
            their performance is not to the standard that is required to
            successfully complete our Courses. In such circumstances, we may
            require you to leave the Course or allow you to defer your place on
            the Course until the following year (during this period of time it
            is our expectation that you will take steps to ensure your
            suitability for the Course).
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              12.2
            </span>
            In the first few weeks of the Course and at such other times as we
            may reasonably elect, we will carry out a review of the materials
            you have completed by that point. We reserve the right to require
            those who do not demonstrate sufficient progress to leave the Course
            after such review.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              12.3
            </span>
            Where we require you to leave the Course under this clause 12, the
            Contract between you and us will come to an end and we will refund a
            pro-rata amount of the Course Fees that have been paid by you. The
            total refund we make shall be at our discretion, but in making any
            refund we will take into account the obligations that we have
            already performed, as well as the engagement and effort you
            displayed towards the completion of the Course.
          </p>

          <h2 className="h5" data-number-before="13">
            Complaints
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              13.1
            </span>
            We take complaints relating to us, our Courses, tutors and other
            students very seriously. Any complaint should initially be raised
            with your Course tutor who will investigate the facts giving rise to
            the complaint and provide their feedback and, where circumstances
            dictate, proposed resolution to you. If the matter is not resolved
            after taking such action, or if your complaint concerns your tutor,
            then you are entitled to raise the complaint with our Chief
            Operating Officer by contacting{" "}
            <a href="mailto:coo@northcoders.com">coo@northcoders.com</a>.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              13.2
            </span>
            If a compliant is raised about you and we find that compliant to be
            valid, where that compliant amounts to a breach of these Terms or
            the Code of Ethics, we may cancel your place on the Course.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              13.3
            </span>
            This clause does not limit or exclude your statutory rights.
          </p>

          <h2 className="h5" data-number-before="14">
            Intellectual Property Rights -{" "}
            <strong>SPECIAL ATTENTION IS DRAWN TO THIS CLAUSE</strong>
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              14.1
            </span>
            We or our licensors own all Intellectual Property Rights in the
            Course Materials. At no time will ownership of such Intellectual
            Property Rights pass to you.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              14.2
            </span>
            Provided that you remain enrolled on the Course and you have fully
            paid applicable Course Fees, we shall provide you with a personal
            licence to use the Course Materials for the sole purpose of
            assisting with your completion of the Course. You may not use the
            Course Materials for any other purpose and, if you do, it may
            constitute a material breach of the Contract.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              14.3
            </span>
            If, whilst studying on the Course, you create Intellectual Property
            Rights <strong>(Created IPRs)</strong>, the default position is that
            those Created IPRs will be owned by you.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              14.4
            </span>
            You hereby grant us a non-exclusive, irrevocable, perpetual and
            royalty-free licence (including the right to sublicense) to use all
            Created IPRs in order for us to advertise and market our Courses.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              14.5
            </span>
            The exception to this default position is that you may, as part of
            the Course, be engaged to carry out software and other technology
            development services for third parties to demonstrate the skills
            that you have developed throughout the Course. In these
            circumstances, you agree that all Intellectual Property Rights that
            you develop shall be automatically assigned to us so that we can in
            turn licence or otherwise assign them to the relevant third party.
            You also agree to irrevocably waive all moral rights which you may at any
            time in the future be entitled to under the Copyright, Designs and
            Patents Act 1988 in favour of us, the relevant third party and our
            collective licensees, successors and assigns.
          </p>

          <h2 className="h5" data-number-before="15">
            Events outside of our control
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              15.1
            </span>
            For the purposes of this clause 15, references to “events outside of
            our control” means any circumstance not within our reasonable
            control including, without limitation:
          </p>

          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.1
              </span>
              acts of God, flood, drought, earthquake or other natural disaster;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.2
              </span>
              epidemic or pandemic;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.3
              </span>
              terrorist attack, civil war, civil commotion or riots, war, threat
              of or preparation for war, armed conflict, imposition of
              sanctions, embargo, or breaking off of diplomatic relations;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.4
              </span>
              nuclear, chemical or biological contamination or sonic boom;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.5
              </span>
              any law or any action taken by a government or public authority;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.6
              </span>
              collapse of buildings, fire, explosion or accident;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.7
              </span>
              any labour or trade dispute, strikes, industrial action or
              lockouts;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.8
              </span>
              non-performance by suppliers, subcontractors or consultants that
              we engage; and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.1.9
              </span>
              interruption or failure of utilities or telecommunications
              services.
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              15.2
            </span>
            We will use our reasonable endeavours to put appropriate measures
            and precautions in place to mitigate the likelihood of events
            outside of our control adversely affecting the Contract.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              15.3
            </span>
            Provided that we comply with clause 15.4, if we are prevented,
            hindered or delayed in or from performing any of our obligations
            under the Contract by an event outside of our control, we will not
            be in breach of the Contract or otherwise liable for any such
            failure or delay in the performance of such obligations. The time
            for our performance of such obligations shall be extended
            accordingly. A practical example of this may be that an event
            outside of our control impacts our ability to provide the Course on
            a certain date or time or at a particular location.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              15.4
            </span>
            Where we are affected by an event outside of our control, we shall:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.4.1
              </span>
              as soon as reasonably practicable after the start of the event
              outside of our control, notify you of it and the effect of it on
              the performance of our obligations under the Contract; and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                15.4.2
              </span>
              use reasonable endeavours to mitigate the effect of the event
              outside of our control on the performance of our obligations under
              the Contract. As described further in clause 10, this may include
              rescheduling the Course or holding in-person events at a different
              location. You agree that you will not unreasonably withhold or
              delay your agreement to any mitigation steps we put in place to
              ensure we can continue to provide the Course when an event outside
              of our control takes place.
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              15.5
            </span>
            If, due to an event outside of our control, we are unable to provide
            the Course for a continuous period of more than 2 months, either you
            or we may terminate the Contract by giving the other 2 weeks’
            written notice.
          </p>

          <h2 className="h5" data-number-before="16">
            Our liability towards you -{" "}
            <strong>SPECIAL ATTENTION IS DRAWN TO THIS CLAUSE</strong>
          </h2>

          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              16.1
            </span>
            Nothing in these Terms accepts to limit or exclude liability for:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.1.1
              </span>
              death or personal injury caused by negligence;
            </p>

            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.1.2
              </span>
              fraud or fraudulent misrepresentation; or
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.1.3
              </span>
              any matter in respect of which it would be unlawful to exclude or
              restrict liability.
            </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              16.2
            </span>
            If we fail to comply with the Contract, we may be responsible for
            loss or damage you suffer that is a foreseeable result of our
            breaking the Contract. Loss or damage is foreseeable if either it is
            obvious that it will happen or if, at the time the Contract was
            made, it was clear to both you and us that it might happen.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              16.3
            </span>
            We are not responsible for loss that you suffer where the loss is:
          </p>

          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.3.1
              </span>
              unexpected, where it is not obvious that it would happen and
              nothing you said to us before we entered into the Contract means
              that we should have expected it (these losses are unforeseeable);
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.3.2
              </span>
              caused by an event outside of our control, provided that we take
              the steps set out in clause 15;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.3.3
              </span>
              avoidable by you;
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.3.4
              </span>
              business losses. Enrolment on our Course is suitable for Consumers
              and, as a consequence, we cannot be liable for any loss suffered
              in connection with your trade, business, craft or profession; or
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                16.3.5
              </span>
              caused by you, such as, for example, where you fail to comply with
              these Terms or your other obligations under the Contract
              (including, without limitation, your obligations relating to
              attendance, conduct and performance standards).
            </p>
          </div>

          <h2 className="h5" data-number-before="17">
            Confidentiality
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              17.1
            </span>
            You may, whilst you are enrolled on the Course (in particular, where
            you are engaged by third parties to carry out software development
            and other technology development services), be exposed to
            confidential information concerning the business, assets, affairs,
            customers, clients or suppliers of Northcoders,
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              17.2
            </span>
            You agree that you may only use or otherwise disclose our
            confidential information:
          </p>
          <div className="ml-6">
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                17.2.1
              </span>
              for the purpose of your studies in connection with the Course; and
            </p>
            <p>
              <span className="text-primary font-bold tracking-widest mr-2">
                17.2.2
              </span>
              otherwise as may be required by law, a court of competent
              jurisdiction or any governmental or regulatory body.
            </p>
          </div>

          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              17.3
            </span>
            Whilst clause 17.1 and clause 17.2 set out your general duties in
            relation to our confidential information, where you are engaged by
            third parties to carry out software development and other technology
            development services either we or the third party in question may
            require that you provide separate confidentiality undertakes which
            operate independently of these Terms and the Contract.
          </p>

          <h2 className="h5" data-number-before="18">
            Data Protection
          </h2>
          <p>
          For information on how we will process your personal data, please review our Privacy Policy,
          which is available at: <a href='https://northcoders.com/privacy-and-cookies/' target='_blank'>https://northcoders.com/privacy-and-cookies/</a>
          </p>

          <h2 className="h5" data-number-before="19">
            Termination by us - <strong>SPECIAL ATTENTION IS DRAWN TO THIS CLAUSE</strong>
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1
            </span>
            We may terminate the Contract and remove you from the Course by giving you immediate written notice where:
          </p>

          <div className="ml-6">
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1.1
            </span>
            your Application contains any false, misleading or incomplete information;
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1.2
            </span>
            you (or any sponsor) fail to pay the Course Fees on the due date for payment and remain in default for a period of 10 days (in this circumstance, we also have the alternative right at our discretion to suspend your place on the Course until payment has been made);
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1.3
            </span>
            in the circumstances provided in clauses 9.3.2, 11, 12 and 15.5;
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1.4
            </span>
            a compliant is made about you which leads to your removal from the Course under clause 13.2;
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1.5
            </span>
            you commit a material breach of any other obligation that you have towards us in these Terms that isn’t remediable by you; or
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.1.6
            </span>
            you commit repeated breaches of these Terms or the documents referred to in them.
          </p>
          </div>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              19.2
            </span>
            Where we exercise our right to terminate under clauses 19.1.1, 19.1.2, 19.1.4, 19.1.5 or 19.1.6. You shall be required to delete or otherwise irrevocably destroy (at our election) all Course Materials that are in your possession custody or control.
          </p>

          <h2 className="h5" data-number-before="20">
            General
          </h2>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              20.1
            </span>
            The Contract (these Terms, documents referred to in them and the Offer Letter) constitutes the entire agreement between you and us and replace all previous agreements, promises, assurances and understandings between you and us in relation to the Contract.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              20.2
            </span>
            We may assign, sub-contract or delegate our rights and obligations under the Contract and these Terms to a different organisation in the Northcoders group of companies. We will notify you in writing if this happens and will ensure that the transfer won’t affect your rights under the Contract. Your place on the Course is non-transferrable.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              20.3
            </span>
            Provided that we may amend these Terms at any time before a Contract between you and us is formed, any amendments to these Terms once a Contract has been formed shall only be effective if they are agreed in writing between you and us.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              20.4
            </span>
            The Contract can only be enforced by you or us. The Contracts (Rights of Third Parties) Act 1999 shall not apply to the Contract.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              20.5
            </span>
            If any provision of the Contract is held to be void or unenforceable in whole or in party by any court or other competent authority, the remainder of the Contract shall continue to apply.
          </p>
          <p>
            <span className="text-primary font-bold tracking-widest mr-2">
              20.6
            </span>
            These Terms and the Contract shall be governed by and construed in accordance with the laws of England and Wales. Both you and we submit to the exclusive jurisdiction of the courts of England and Wales to determine any dispute arising out of or in connection with these Terms and the Contract. If you are based in Scotland, both you and we can also bring claims before the Scottish courts.
          </p>

        </div>
      </BasicPage>
    </>
  );
};

export default StudentsTermAndConditionsPage;
